const config = require('../helpers/config.json');
export const getRoleManagment = (userId) => {
    return (dispatch) => {
        /// get request
        fetch(`${config['baseUrl']}/admin/all_roles.php`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "auth_id": `${userId}` },
        }).then(res => res.json()).then((response) => {
            const roleManagment = response.data

            // console.log("kkkkk", response);
            dispatch({
                type: "GET_ROLEMANAGMENT",
                roleManagment: roleManagment,
                roleManagmentResponse: "got it"
            });
        }).catch((error) => {
            console.log("error", error);
            dispatch({
                type: "GET_PERSONAL",
                roleManagment: {},
                roleManagmentResponse: null
            });
        })

    }


}


export const postContactUs = (userId, role_name, all_rights, handle_user, ban_user, handle_recruiter, ban_recruiter) => {
    console.log(userId, role_name, all_rights, handle_user, ban_user, handle_recruiter, ban_recruiter)
    return (dispatch) => {
        dispatch({
            type: "CREATE_ROLE_RESET",
        });
        var data =
        {
            "role_name": role_name,
            "all_rights": all_rights,
            "handle_user": handle_user,
            "ban_user": ban_user,
            "handle_recruiter": handle_recruiter,
            "ban_recruiter": ban_recruiter,
            "userId": userId
        }

        console.log("ddddd", data)
        /// post request
        fetch(`${config['baseUrl']}/admin/create_role.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "auth_id": `${userId}`, },
            body: JSON.stringify({
                "role_name": role_name,
                "all_rights": all_rights,
                "handle_user": handle_user,
                "ban_user": ban_user,
                "handle_recruiter": handle_recruiter,
                "ban_recruiter": ban_recruiter,
            })
        }).then((response) => {
            console.log("pppppp", response);
            dispatch({
                type: "CREATE_ROLE_SUCCESS",
                createRoleResponse: response,

            });
                window.location="/rolemanagment"
                   
        }).catch((error) => {
            console.log(error)
            dispatch({
                type: "CREATE_ROLE_FAIL",
                createRoleResponse: "creation failed",
                //pageName: PGN.COLORS_PAGE_NAME
            });
            alert("Please Check Your Internet Connection...")
        })
    }
}




export const deleteRole = (userId,role_id) => {
    console.log(userId,role_id)
    return (dispatch) => {
        dispatch({
            type: "CREATE_ROLE_RESET",
        });
        var data = 
            {
                "role_id":role_id,
                "user_id":userId
            }
        
        console.log("ddddd",data)
        /// post request
        fetch(`${config['baseUrl']}/admin/delete_role.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"auth_id": `${userId}`, },
            body: JSON.stringify({
                "role_id":role_id!==null?Number(role_id):role_id,
            })
        }).then((response) => {
            console.log("pppppp", response);
            dispatch({
                type: "CREATE_ROLE_SUCCESS",
                createRoleResponse: response,

            });
                window.location="/rolemanagment"
                   
            window.location= "/rolemanagment"

        }).catch((error) => {
            console.log(error)
            dispatch({
                type: "CREATE_ROLE_FAIL",
                createRoleResponse: "creation failed",
                //pageName: PGN.COLORS_PAGE_NAME
            });
            alert("Please Check Your Internet Connection...")
        })
    }
}


