const initState = {
    recruitersDetails: {},
    recruitersDetailsResponse: null,
    
}

const recruitersDetailsReducer = (state = initState, action) => {
    if (action.type === 'GET_RECRUITERSDETAILS') {
        return {
            ...state,
            recruitersDetails: action.recruitersDetails,
            recruitersDetailsResponse:action.recruitersDetailsResponse
        }
    }
    else if (action.type === 'RECRUITERSDETAILS_RESET') {
        return {
            ...state,
            recruitersDetailsResponse: null,
            
        }
    }
    else if (action.type === 'RECRUITERSDETAILS_SUCCESS') {
        return {
            ...state,
            
            recruitersDetailsResponse: action.recruitersDetailsResponse,
        }
    }
    else if (action.type === 'RECRUITERSDETAILS_FAIL') {
        return {
            ...state,
            
            recruitersDetailsResponse: action.recruitersDetailsResponse,
        }
    }

    return state;
}
export default recruitersDetailsReducer;