import React,{useState} from "react";
import "../NavBar/Navbar.css";
import Logo from "../../assests/mob-logo.png";
import {Link,NavLink} from "react-router-dom"
import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { signOut } from "../../Actions/authActions";
import Sidebar from "../../components/SideBar/Sidebar";


function Navbar(props) {
  const [sidebar,setsidebar]=useState(false)
  const[dropmenu,setdropmenu]=useState(false)
  const[dropmenu1,setdropmenu2]=useState(false)

  const dropfunc=()=>{
      setdropmenu(true)
  }
  const dropfunc2=()=>{
      setdropmenu(false)
  }

  const dropfunc3=()=>{
    setdropmenu2(true)
  }
  const dropfunc4=()=>{
    setdropmenu2(false)
  }
  const style = {
    height: '192px',
    transition: "0.4s all",
    opacity:"1",
    width:"100%",
    display:"block"
  };
  const style2 = {
    height: '0px',
    width:"0px",
    transition: "0.4s all",
    opacity:"0",
    display:"none"
  }
  const style3 = {
    height: '119px',
    transition: "0.4s all",
    opacity:"1",
    width:"100%",
    display:"block"
  };
  return (
    <>
    {sidebar == true?
      <div style={{width:sidebar == true?"70%":"0px"}} className="main-sidebar">
        <ul className="side-main">
        <NavLink exact activeClassName="selectedOne" to="/dashboard">
          <li className="nav-text nav-titles active2">
            <div className="sidebar-menu-icon">
              <i class="fa fa-tasks" aria-hidden="true"></i>
            </div>
            Dashboard
          </li>
        </NavLink>
        <div style={{zIndex:-999}} className="jobs_hover ">
          <li onClick={!dropmenu?dropfunc:dropfunc2} className="nav-text nav-titles active2 jobs_hover_inner usersidebar">
            <div className="sidebar-menu-icon">
            <i class="fa fa-briefcase" aria-hidden="true"></i>
            </div>
            Jobs
            {dropmenu ?<i class="fa fa-caret-down drop_down_jobs" aria-hidden="true"></i>:<i class="fa fa-caret-up drop_down_jobs" aria-hidden="true"></i>} 
          </li>
          <div style={!dropmenu ?style2:style} className="usersidebar_sidemenu">
              <NavLink exact activeClassName="selectedOne" to="/jobs">
                <li className="nav-text nav-titles2 active3 ">
                  <div className="sidebar-menu-icon">
                    <i class="fa fa-briefcase" aria-hidden="true"></i>
                  </div>
                    All Jobs
                </li>
              </NavLink>
              <NavLink exact activeClassName="selectedOne" to="/reportjobs">
                <li className="nav-text nav-titles2 active3">
                  <div className="sidebar-menu-icon">
                    <i class="fa fa-briefcase" aria-hidden="true"></i>
                  </div>
                  Report Jobs
                </li>
              </NavLink>
              <NavLink exact activeClassName="selectedOne" to="/pausejobs">
                <li className="nav-text nav-titles2 active3">
                  <div className="sidebar-menu-icon">
                    <i class="fa fa-briefcase" aria-hidden="true"></i>
                  </div>
                  Pause Jobs
                </li>
              </NavLink>
          </div>
        </div>
        <div style={{zIndex:999}}>
        <NavLink exact activeClassName="selectedOne" to="/hearaboutus">
          <li className="nav-text nav-titles active2">
            <div className="sidebar-menu-icon">
            <i class="fa fa-ear" aria-hidden="true"></i>
            </div>
            Here About Us
          </li>
        </NavLink>
        <NavLink  exact activeClassName="selectedOne" to="/users">
          <li className="nav-text nav-titles mt-0 active2 ">
            <div className="sidebar-menu-icon">
              <i class="fa fa-user" aria-hidden="true"></i>
            </div>
            Users
           
          </li>
        </NavLink>
        <NavLink exact activeClassName="selectedOne" to="/resumes">
          <li className="nav-text nav-titles active2">
            <div className="sidebar-menu-icon">
            <i class="fa fa-file" aria-hidden="true"></i>
            </div>
            Resumes
          </li>
        </NavLink>


        <div style={{zIndex:-999}} className="jobs_hover ">
          <li onClick={!dropmenu1?dropfunc3:dropfunc4} className="nav-text nav-titles active2 jobs_hover_inner usersidebar">
            <div className="sidebar-menu-icon">
            <i class="fa fa-credit-card" aria-hidden="true"></i>
            </div>
            Invoice
            {dropmenu1 ?<i class="fa fa-caret-down drop_down_jobs" aria-hidden="true"></i>:<i class="fa fa-caret-up drop_down_jobs" aria-hidden="true"></i>} 
          </li>
          <div style={!dropmenu1?style2:style3} className="usersidebar_sidemenu">
              <NavLink exact activeClassName="selectedOne" to="/invoice/paid">
                <li className="nav-text nav-titles2 active3 ">
                  <div className="sidebar-menu-icon">
                    <i class="fa fa-credit-card" aria-hidden="true"></i>
                  </div>
                    Paid invoice
                </li>
              </NavLink>
              <NavLink exact activeClassName="selectedOne" to="/invoice/due">
                <li className="nav-text nav-titles2 active3">
                  <div className="sidebar-menu-icon">
                    <i class="fa fa-credit-card" aria-hidden="true"></i>
                  </div>
                  Pending invoice
                </li>
              </NavLink>
          </div>
        </div>


        <NavLink exact activeClassName="selectedOne" to="/managetermsandconditions">
          <li className="nav-text nav-titles active2">
            <div className="sidebar-menu-icon">
            <i class="fa fa-paperclip" aria-hidden="true"></i>
            </div>
            Manage Terms And Conditions
          </li>
        </NavLink>
        <NavLink exact activeClassName="selectedOne" to="/manageprivacypolicy">
          <li className="nav-text nav-titles active2">
            <div className="sidebar-menu-icon">
            <i class="fa fa-info-circle" aria-hidden="true"></i>
            </div>
            Manage Privacy Policy
          </li>
        </NavLink>
        <NavLink exact activeClassName="selectedOne" to="/contact">
          <li className="nav-text nav-titles active2">
            <div className="sidebar-menu-icon">
            <i class="fa fa-question-circle" aria-hidden="true"></i>
            </div>
            Contact Us
          </li>
        </NavLink>
        <NavLink exact activeClassName="selectedOne" to="/aboutus">
          <li className="nav-text nav-titles active2">
            <div className="sidebar-menu-icon">
            <i class="fa fa-address-card" aria-hidden="true"></i>
            </div>
            Manage About Us
          </li>
        </NavLink>
        <NavLink exact activeClassName="selectedOne" to="/helpcenter">
          <li className="nav-text nav-titles active2">
            <div className="sidebar-menu-icon">
            <i class="fa fa-question-circle" aria-hidden="true"></i>
            </div>
            Help Center
          </li>
        </NavLink>
        </div>
        {/*<NavLink exact activeClassName="selectedOne" to="/customersupport">
          <li className="nav-text nav-titles active2">
            <div className="sidebar-menu-icon">
              <i class="fa fa-user" aria-hidden="true"></i>
            </div>
            Customer Support
          </li>
        </NavLink>
        <NavLink exact activeClassName="selectedOne" to="/chat">
          <li className="nav-text nav-titles active2">
            <div className="sidebar-menu-icon">
              <i class="fa fa-user" aria-hidden="true"></i>
            </div>
            Chat
          </li>
        </NavLink>
        <NavLink exact activeClassName="selectedOne" to="/packagescontrolpanel">
          <li className="nav-text nav-titles active2">
            <div className="sidebar-menu-icon">
              <i class="fa fa-user" aria-hidden="true"></i>
            </div>
            Packages Control Panel
          </li>
        </NavLink>
        <NavLink exact activeClassName="selectedOne" to="/inAppchanges">
          <li className="nav-text nav-titles active2">
            <div className="sidebar-menu-icon">
              <i class="fa fa-user" aria-hidden="true"></i>
            </div>
            In App Changes
          </li>
        </NavLink>
        <NavLink exact activeClassName="selectedOne" to="/rolemanagment">
          <li className="nav-text nav-titles active2">
            <div className="sidebar-menu-icon">
              <i class="fa fa-user" aria-hidden="true"></i>
            </div>
            Role Managment
          </li>
        </NavLink> */}
        <Link
          onClick={() => props.signOut()}
        >
          <li className="nav-text nav-titles active2">
          <div className="sidebar-menu-icon">
          <i class="fa fa-sign-out" aria-hidden="true"></i>
            </div>
            Logout</li>
        </Link>
      </ul>
      </div>:""
    }
    <div className="parent-nav sticky">
      <div className="nav-back-parent">
        <Link to="/dashboard">
        <section className="nav-img">
          <img src={Logo} alt="Logo" />
        </section>
        </Link>
        <section className="user-info">
          <div className="user-name">
          <i  onClick={()=>{setsidebar(true)}} class="fa fa-bars" aria-hidden="true"></i>
          </div>
        </section>
      </div>
    </div>
    </>
  );
}

export default Navbar;