const initState = {
    allpersonal: {},
    allpersonalResponse: null,
}

const allpersonalReducer = (state = initState, action) => {
    if (action.type === 'GET_ALLPERSONAL') {
        return {
            ...state,
            allpersonal: action.allpersonal,
            allpersonalResponse: action.allpersonalResponse
        }
    }
    else if (action.type === 'ALLPERSONAL_RESET') {
        return {
            ...state,
            allpersonalResponse: null,

        }
    }
    else if (action.type === 'ALLPERSONAL_SUCCESS') {
        return {
            ...state,

            allpersonalResponse: action.allpersonalResponse,
        }
    }
    else if (action.type === 'ALLPERSONAL_FAIL') {
        return {
            ...state,

            allpersonalResponse: action.allpersonalResponse,
        }
    }

    return state;
}
export default allpersonalReducer;