import React from "react"
import spinner from "./loaderadmin.gif"
import "./fullpageloader.css"

const FullPageLoader = ({ videoUploadOne }) => {
    return (
        <div style={{height:"100vh"}} className="d-flex align-items-center justify-content-center">
            <img src={spinner} alt="loading" />
        </div>
    )
}
export default FullPageLoader