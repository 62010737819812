const initState = {
    recruiters: {},
    recruitersResponse: null,

}

const recruitersReducer = (state = initState, action) => {
    if (action.type === 'GET_RECRUITERS') {
        return {
            ...state,
            recruiters: action.recruiters,
            recruitersResponse: action.recruitersResponse
        }
    }
    else if (action.type === 'RECRUITERS_RESET') {
        return {
            ...state,
            recruitersResponse: null,

        }
    }
    else if (action.type === 'RECRUITERS_SUCCESS') {
        return {
            ...state,

            recruitersResponse: action.recruitersResponse,
        }
    }
    else if (action.type === 'RECRUITERS_FAIL') {
        return {
            ...state,

            recruitersResponse: action.recruitersResponse,
        }
    }

    return state;
}
export default recruitersReducer;