import React from 'react';
import "./Logout.css";

function Logout() {
    return (
        <div>
            <h1>Logout</h1>
        </div>
    )
}

export default Logout;
