import React, { useState, useEffect } from "react";
import "../Chat/Chat.css";
import { BsPlayFill } from "react-icons/bs";
import Modal from "react-modal";
import linkIcon from "../../assests/chat-modal-1.svg";
import ImageIcon from "../../assests/chat-modal-2.svg";
import Navbar from "../../components/NavBar/Navbar";
import Sidebar from "../../components/SideBar/Sidebar";

Modal.setAppElement("#root");

function Chat() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <>
    <Navbar/>
    <Sidebar/>
    <div className="all-main-chat">
      <section className="chat-dash">
        <div className="chat-child-1">
          <button className="chat-btn" onClick={() => setModalIsOpen(true)}>
            Create Messages
          </button>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "transparent",
              },
              content: {
                position: "absolute",
                height: "500px",
                width: "750px",
                top: "70px",
                left: "300px",
                right: "40px",
                bottom: "40px",
                border: "1px solid #fff",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "20px",
                outline: "none",
                padding: "20px",
                boxShadow: "0 0 5px 5px #f2f2f2",
              },
            }}
          >
            <section className="create-message-modal-parent">
              <div className="chat-modal-child-1">
                <button className="chat-send-to-all">Send To All</button>
                <button className="chat-send-to-candidates">
                  Send To Candidates
                </button>
                <button className="chat-send-to-recriters">
                  Send To Recruiters
                </button>
                <button className="chat-send-to-admins">Send To Admins</button>
              </div>
              <div className="chat-modal-child-2">
                <p>Type Message</p>
                <div className="chat-modal-input-div">
                 <textarea type="text" className="message-input"></textarea>
                 {/* <button className="absolute-btn-chat"><img src={ImageIcon} alt=""/></button>
                 <button className="absolute-btn-chat-02"><img src={linkIcon} alt=""/></button> */}
                </div>
              </div>
              <div className="chat-modal-child-3">
                <button className="chat-lower-btn-1">Cancel</button>
                <button className="chat-lower-btn-2">Draft</button>
                <button className="chat-lower-btn-3">Send</button>
              </div>
            </section>
          </Modal>
        </div>
      </section>

      <section className="chat-table-parent">
        <div className="chat-sub-div">
          <div className="chat-table-child">
            <table className="chat-table">
              <thead>
                <tr>
                  <th className="admin-first-radius">MESSAGE</th>
                  <th>DRAFT</th>
                  <th>DATE/TIME</th>
                  <th className="admin-last-radius">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    lozcjhsidjcbshibcshbhsdbshdbcisdbcibscisdibbisdcsndkcjbsdjhcbj
                  </td>
                  <td>Draft Safe</td>
                  <td>24 March 2021</td>
                  <td>
                    Send
                    <BsPlayFill />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
    </>
  );
}

export default Chat;
