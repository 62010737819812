import { combineReducers } from 'redux';
import persistReducer from "redux-persist/es/persistReducer";
import storage from 'redux-persist/lib/storage';
import dashboardReducer from "../reducers/dashboardReducer";
import authReducer from "../reducers/authReducer";
import logoutReducer from "../reducers/logoutReducer";
import userReducer from "../reducers/userReducer";
import adminControlsReducer from "../reducers/adminControlsReducer";
import faqsReducer from "../reducers/faqsReducer";
import recruitersReducer from "../reducers/recruitersReducer";
import candidatesReducer from "../reducers/candidateReducer";
import roleManagmentReducer from "../reducers/roleManagmentReducer";
import userInfoReducer from "../reducers/userInfoReducer";
import adminDetailsReducer from "../reducers/adminDetailsReducer";
import salesReducer from "../reducers/SalesReducer";
import recruitersDetailsReducer from "../reducers/recruitersDetailsReducer";
import candidateDetailsReducer from "../reducers/candidateDetailsReducer";
import customerSupportReducer from "../reducers/customerSupportReducer";
import createRoleReducer from "../reducers/createRolePostReducer";
import createAdminReducer from "../reducers/createAdminPostReducer";
import inAppChangesReducer from "../reducers/inAppChangesReducer";
import allprofessionalReducer from "./allprofessionalgetReducer"
import allqualificationReducer from "./allqualificationgetReducer"
import editRecruitersReducer from "./EditRecruitersReducer"
import allpersonalReducer from "./allpersonalgetReducer"

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
}
const mainReducer = combineReducers({
    dashboardReducer: dashboardReducer,
    authReducer: authReducer,
    logoutReducer: logoutReducer,
    userReducer: userReducer,
    adminControlsReducer: adminControlsReducer,
    faqsReducer: faqsReducer,
    recruitersReducer: recruitersReducer,
    candidatesReducer: candidatesReducer,
    roleManagmentReducer: roleManagmentReducer,
    userInfoReducer: userInfoReducer,
    adminDetailsReducer:adminDetailsReducer,
    salesReducer:salesReducer,
    recruitersDetailsReducer:recruitersDetailsReducer,
    candidateDetailsReducer:candidateDetailsReducer,
    customerSupportReducer:customerSupportReducer,
    createRoleReducer:createRoleReducer,
    createAdminReducer:createAdminReducer,
    inAppChangesReducer:inAppChangesReducer,
    allprofessionalReducer:allprofessionalReducer,
    allqualificationReducer:allqualificationReducer,
    editRecruitersReducer:editRecruitersReducer,
    allpersonalReducer:allpersonalReducer
})

export default persistReducer(persistConfig, mainReducer);
