const config = require('.././helpers/config.json');

export const SignIn = (username, password) => {
    return (dispatch) => {
        dispatch({
            type: "RESET_AUTH_MESSAGE",
        });
        console.log("email and pass", username, password);
        console.log("email and pass", username, password);
        fetch(`https://api.cvvlogs.com/cv-tube/api.v.1/admin/login.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "username": username,
                "password": password
            })
        }).then(res => {
            console.log("res aqib", res)
            if (res.status !== 200) {
                alert("Incorrect Email Or Password...");

            }
            // localStorage.setItem("message",res.status)
            // alert("Loading....")
            return res.json();

        }).then((response) => {
            console.log("data", response)
            const auth_id = response.data.auth_id;
            const empId = response.data.id;
            const premium = response.data.premium;
            const first_name = response.data.username
            const role =response.data.role
            // hideLoader()
            // console.log(response);
            // console.log("ffff",respone.data)
            dispatch({
                type: "SIGN_IN",
                authError: null,
                auth_id,
                employee_id: empId,
                authMessage: "Signing you in...",
            });
            if (auth_id !== "" && auth_id !== null && auth_id !== undefined) {
                window.location = "/dashboard"
            } else {
                alert("email or password is incorrect...")
            }
            localStorage.setItem("auth_id", auth_id)
            localStorage.setItem("first_name", first_name)
            localStorage.setItem("role", role)


            // window.location.reload(false)
        }).catch((error) => {
            const authError = JSON.stringify(error)
            console.log(authError);
            dispatch({
                type: "SIGN_IN",
                authError,
                auth_id: null,
                employee_id: null,
                authMessage: null
            });
        })
        // alert("Please Check Your Internet Connection...")
    };
}

export const signOut = (props) => {
    localStorage.removeItem('user');
    localStorage.removeItem('first_name');
    window.location = "/"
    return {
        type: "SIGN_OUT",
    };
};




