import React from 'react';
import './App.css';
// import Login from './components/Login/Login';
import RoutingInCVTube from "./RoutingInJSX/RoutingInCVTube";

function App() {
  return (
      <RoutingInCVTube/>
  );
}

export default App;


