const initState = {
    createAdmin: {},
    createAdminResponse: null,
}

const createAdminReducer = (state = initState, action) => {
    if (action.type === 'GET_CREATE_ADMIN') {
        return {
            ...state,
            createAdmin: action.createAdmin,
            createAdminResponse: action.createAdminResponse
        }
    }
    else if (action.type === 'CREATE_ADMIN_RESET') {
        return {
            ...state,
            createAdminResponse: null,

        }
    }
    else if (action.type === 'CREATE_ADMIN_SUCCESS') {
        return {
            ...state,

            createAdminResponse: action.createAdminResponse,
        }
    }
    else if (action.type === 'CREATE_ADMIN_FAIL') {
        return {
            ...state,

            createAdminResponse: action.createAdminResponse,
        }
    }

    return state;
}
export default createAdminReducer;