const initState = {
    adminDetails: {},
    adminDetailsResponse: null,
    
}

const adminDetailsReducer = (state = initState, action) => {
    if (action.type === 'GET_ADMINDETAILS') {
        return {
            ...state,
            adminDetails: action.adminDetails,
            adminDetailsResponse:action.adminDetailsResponse
        }
    }
    else if (action.type === 'ADMINDETAILS_RESET') {
        return {
            ...state,
            adminDetailsResponse: null,
            
        }
    }
    else if (action.type === 'ADMINDETAILS_SUCCESS') {
        return {
            ...state,
            
            adminDetailsResponse: action.adminDetailsResponse,
        }
    }
    else if (action.type === 'ADMINDETAILS_FAIL') {
        return {
            ...state,
            
            adminDetailsResponse: action.adminDetailsResponse,
        }
    }

    return state;
}
export default adminDetailsReducer;