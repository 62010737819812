const initState = {
    adminControls: {},
    adminControlsResponse: null,
}

const adminControlsReducer = (state = initState, action) => {
    if (action.type === 'GET_ADMINCONTROLS') {
        return {
            ...state,
            adminControls: action.adminControls,
            adminControlsResponse:action.adminControlsResponse
        }
    }
    else if (action.type === 'ADMINCONTROLS_RESET') {
        return {
            ...state,
            adminControlsResponse: null,
            
        }
    }
    else if (action.type === 'ADMINCONTROLS_SUCCESS') {
        return {
            ...state,
            
            adminControlsResponse: action.adminControlsResponse,
        }
    }
    else if (action.type === 'ADMINCONTROLS_FAIL') {
        return {
            ...state,
            
            adminControlsResponse: action.adminControlsResponse,
        }
    }

    return state;
}
export default adminControlsReducer;