const initState = {
    customerSupport: {},
    customerSupportResponse: null,
}

const customerSupportReducer = (state = initState, action) => {
    if (action.type === 'GET_CUSTOMER_SUPPORT') {
        return {
            ...state,
            customerSupport: action.customerSupport,
            customerSupportResponse:action.customerSupportResponse
        }
    }
    else if (action.type === 'CUSTOMER_SUPPORT_RESET') {
        return {
            ...state,
            customerSupportResponse: null,
            
        }
    }
    else if (action.type === 'CUSTOMER_SUPPORT_SUCCESS') {
        return {
            ...state,
            
            customerSupportResponse: action.customerSupportResponse,
        }
    }
    else if (action.type === 'CUSTOMER_SUPPORT_FAIL') {
        return {
            ...state,
            
            customerSupportResponse: action.customerSupportResponse,
        }
    }

    return state;
}
export default customerSupportReducer;