const initState = {
    roleManagment: {},
    roleManagmentResponse: null,
}

const roleManagmentReducer = (state = initState, action) => {
    if (action.type === 'GET_ROLEMANAGMENT') {
        return {
            ...state,
            roleManagment: action.roleManagment,
            roleManagmentResponse:action.roleManagmentResponse
        }
    }
    else if (action.type === 'ROLEMANAGMENT_RESET') {
        return {
            ...state,
            roleManagmentResponse: null,
            
        }
    }
    else if (action.type === 'ROLEMANAGMENT_SUCCESS') {
        return {
            ...state,
            
            roleManagmentResponse: action.roleManagmentResponse,
        }
    }
    else if (action.type === 'ROLEMANAGMENT_FAIL') {
        return {
            ...state,
            
            roleManagmentResponse: action.roleManagmentResponse,
        }
    }

    return state;
}
export default roleManagmentReducer;