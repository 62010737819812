const initState = {
    editRecruiters: {},
    editRecruitersResponse: null,
}

const editRecruitersReducer = (state = initState, action) => {
    if (action.type === 'GET_EDIT_RECRUITERS') {
        return {
            ...state,
            editRecruiters: action.editRecruiters,
            editRecruitersResponse: action.editRecruitersResponse
        }
    }
    else if (action.type === 'EDIT_RECRUITERS_RESET') {
        return {
            ...state,
            editRecruitersResponse: null,

        }
    }
    else if (action.type === 'EDIT_RECRUITERS_SUCCESS') {
        return {
            ...state,

            editRecruitersResponse: action.editRecruitersResponse,
        }
    }
    else if (action.type === 'EDIT_RECRUITERS_FAIL') {
        return {
            ...state,

            editRecruitersResponse: action.editRecruitersResponse,
        }
    }

    return state;
}
export default editRecruitersReducer;