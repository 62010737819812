const initState = {
    user: {},
    userResponse: null,
}

const userReducer = (state = initState, action) => {
    if (action.type === 'GET_USER') {
        return {
            ...state,
            user: action.user,
            userResponse:action.userResponse
        }
    }
    else if (action.type === 'USER_RESET') {
        return {
            ...state,
            userResponse: null,
            
        }
    }
    else if (action.type === 'USER_SUCCESS') {
        return {
            ...state,
            
            userResponse: action.userResponse,
        }
    }
    else if (action.type === 'USER_FAIL') {
        return {
            ...state,
            
            userResponse: action.userResponse,
        }
    }

    return state;
}
export default userReducer;