import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import Jobs from "../pages/jobs/jobs";
import Reportjobs from "../pages/jobs/reportjob";
import ReportjobsDetail from "../pages/jobs/reportjobs";
import Pausejobs from "../pages/jobs/pausejob";
import Appliedjobs from "../pages/jobs/appliedjob";
import Candidate from "../pages/users/cadidate";
import Employeer from "../pages/users/employeer";
import ManageTermsAndConditions from "../pages/Manage-Terms-and-Conditions/ManageTermsAndConditions";
import ManagePrivacyPolicy from "../pages/Manage-Privacy-Policy/ManagePrivacyPolicy";
import Aboutus from "../pages/about/aboutus";
import HelpCenter from "../pages/helpcenter/helpcenter";
import Resumes from "../pages/resumes/resumes";
import ResumesDetail from "../pages/resumes/resumesdetail";
import Invoice from "../pages/invoice/invoicepaid";
import InvoiceD from "../pages/invoice/dueinvoice";
import CustomerSupport from "../pages/Customer-Support/CustomerSupport";
import Chat from "../pages/Chat/Chat";
import UserProfile from "../pages/user-profile/profile";
import Contact from "../pages/contact/contact";
import InAppChanges from "../pages/In-App-Changes/InAppChanges";
import Logout from "../pages/Logout/Logout";
import RoleManagment from "../pages/Role-Managment/RoleManagment";
import Login from "../components/Login/Login";
import "./ActiveRouting.css";
import HearAboutUs from "../pages/jobs/hearaboutus";

function RoutingInCVTube() {
  return (
      <Router>
        <Switch>
          {
            localStorage.getItem("user")!==undefined&&localStorage.getItem("user")!==null&&localStorage.getItem("user")!==""?
           <>
            <Route path="/" exact component={Dashboard} />
            <Route path="/dashboard" exact component={Dashboard} />
            <Route path="/reportjobs" exact component={Reportjobs} />
            <Route path="/pausejobs" exact component={Pausejobs} />
            <Route path="/applied/jobs" exact component={Appliedjobs} />
            <Route path="/getAlljobs/report/" exact component={ReportjobsDetail} />
            <Route path="/jobs" exact component={Jobs} />
            <Route path="/invoice/paid" exact component={Invoice} />
            <Route path="/invoice/due" exact component={InvoiceD} />
            <Route path="/employer" exact component={Employeer} />
            <Route path="/candidate" exact component={Candidate} />
            <Route path="/UserProfile" exact component={UserProfile} />
            
            <Route
              path="/managetermsandconditions"
              exact
              component={ManageTermsAndConditions}
            />
            <Route
              path="/manageprivacypolicy"
              exact
              component={ManagePrivacyPolicy}
            />
            <Route path="/aboutus" exact component={Aboutus} />
            <Route path="/helpcenter" exact component={HelpCenter} />
            <Route path="/resumes" exact component={Resumes} />
            <Route path="/hearaboutus" exact component={HearAboutUs} />
            <Route path="/resumes/detail/" exact component={ResumesDetail} />
            <Route path="/customersupport" exact component={CustomerSupport} />
            <Route path="/chat" exact component={Chat} />
            <Route
              path="/contact"
              exact
              component={Contact}
            />
            <Route path="/inAppchanges" exact component={InAppChanges} />
            <Route path="/logout" exact component={Logout} />
            <Route path="/rolemanagment" exact component={RoleManagment} />
           </>
            :
            <Route path="*" exact component={Login} />
          }
        
        </Switch>
      </Router>
  );
}

export default RoutingInCVTube;
