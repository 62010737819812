import React,{useState,useEffect} from "react";
import "../In-App-Changes/InAppChanges.css";
import Navbar from "../../components/NavBar/Navbar";
import Modal from "react-modal";
import Sidebar from "../../components/SideBar/Sidebar";
import { getInAppChanges,postCreateIndustry,postDeleteIndustry,postCreateLanguages,postDeleteLanguages,postCreateFunctionalArea, postDeleteFunctionalArea, postCreateSkills, postDeleteSkills } from "../../Actions/inAppChangesAction";
import { connect } from "react-redux";

Modal.setAppElement("#root");

function InAppChanges(props) {
  const [functionalAreaModal, setFunctionalAreaModal] = useState(false);
  const [industryModal, setIndustryModal] = useState(false);
  const [languagesModal, setLanguagesModal] = useState(false);
  const [skillsModal, setSkillsModal] = useState(false);

  const [createIndustry, setCreateIndustry] = useState("");
  const [deleteIndustry,setDeleteIndustry] = useState("");

  const [createLanguages,setCreateLanguages] = useState("");
  const [deleteLanguages,setDeleteLanguages] = useState("");

  const [createFunctionalArea, setCreateFunctionalArea] = useState("");
  const [deleteFunctionalArea, setDeleteFunctionalArea] = useState("");

  const [createSkills, setCreateSkills] = useState("")
  const [deleteSkills, setDeleteSkills] = useState("")

  useEffect(() => {
    loadGetPersonal(localStorage.getItem("auth_id"));
  }, []);
  const loadGetPersonal = async (userId) => {
    await props.getInAppChanges(userId);
    return null;
  };

  const handleIndustry = async()=>{
    await props.postCreateIndustry(
      localStorage.getItem("auth_id"),createIndustry
    );
    setIndustryModal(false)
  }

  const handleDeleteIndustry = async()=>{
    const to_del = "ind"
    // console.log(deleteIndustry,to_del);
    await props.postDeleteIndustry(
      localStorage.getItem("auth_id"),deleteIndustry,to_del
    );
  }

  const handleLanguages = async()=>{
    await props.postCreateLanguages(
      localStorage.getItem("auth_id"),createLanguages
    );
    setIndustryModal(false)
  }

  const handleLanguagesDelete = async()=>{
    const to_del = "lang"
    // console.log(deleteLanguages,to_del);
    await props.postDeleteLanguages(
      localStorage.getItem("auth_id"),deleteLanguages,to_del
    );
  }

  const handleCreateFunctionalArea = async()=>{
    await props.postCreateFunctionalArea(
      localStorage.getItem("auth_id"),createFunctionalArea
    );
    setFunctionalAreaModal(false)
  }

  const handleDeleteFunctionalArea = async()=>{
    const to_del = "func"
    // console.log(deleteFunctionalArea,to_del);
    await props.postDeleteFunctionalArea(
      localStorage.getItem("auth_id"),deleteFunctionalArea,to_del
    );
  }

  const handleCreateSkills = async()=>{
    await props.postCreateSkills(
      localStorage.getItem("auth_id"),createSkills
    );
    setSkillsModal(false)
  }

  const handleDeleteSkills = async()=>{
    const to_del = "sk"
    // console.log(deleteSkills,to_del);
    await props.postDeleteSkills(
      localStorage.getItem("auth_id"),deleteSkills,to_del
    );
  }

  return (
    <>
    <Navbar/>
    <Sidebar/>
    <div className="changes-parent all-main">
      <section className="changes-row-1">
        <div className="changes-1">
          <h1 className="changes-heading">Functional Area</h1>
          {/* <button onClick={()=>console.log(deleteIndustry)}>Click</button> */}
          <button className="changes-add-btn" onClick={() => setFunctionalAreaModal(true)}>+ Add Functional</button>
          {/* Modal Starts */}
          <Modal
            isOpen={functionalAreaModal}
            onRequestClose={() => setFunctionalAreaModal(false)}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "transparent",
              },
              content: {
                position: "absolute",
                height: "200px",
                width: "500px",
                top: "80px",
                left: "300px",
                right: "40px",
                bottom: "40px",
                border: "1px solid #fff",
                background: "#fff",
                overflow: "hidden",
                WebkitOverflowScrolling: "touch",
                borderRadius: "10px",
                outline: "none",
                padding: "20px",
              },
            }}
          >
            <section className="package-modal-parent">
              <div>
                <div className="modal-pack-name">
                  <label>Create Functional Area</label>
                  <input type="text" 
                  className="modal-package-input" 
                  value={createFunctionalArea}
                  onChange={(e)=>setCreateFunctionalArea(e.target.value)}
                  />
                </div>
                <div className="modal-pack-buttons"> 
                    <button className="pack-modal-create" onClick={handleCreateFunctionalArea}>Create</button>
                </div>
              </div>
            </section>
          </Modal>
          {/* Modal Ends */}
          <div className="something-change-in-app">
            {props.inAppChangesReducer.inAppChanges.functional_area && 
            props.inAppChangesReducer.inAppChanges.functional_area.length > 0 ? 
            props.inAppChangesReducer.inAppChanges.functional_area.map( (func)=>(
          <section className="change-child-01">
            <input type="radio" 
            className="change-input" 
            value={deleteFunctionalArea}
            onChange={()=>setDeleteFunctionalArea(func.id)}
            name={deleteFunctionalArea}
            />
            <h3>{func.name}</h3>
          </section>
            )):""}
          </div>
          <button style={{height:"30px",width:"140px",marginTop:"30px",marginLeft:"200px",fontWeight:"bold",width:"80px"}} onClick={handleDeleteFunctionalArea}>Delete</button>
        </div>

        <div className="changes-1" style={{marginLeft:"40px"}}>
          <h1 className="changes-heading">Add Industry</h1>
          <button className="changes-add-btn" onClick={() => setIndustryModal(true)}>+ Add Industry</button>
          {/* Modal Starts */}
          <Modal
            isOpen={industryModal}
            onRequestClose={() => setIndustryModal(false)}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "transparent",
              },
              content: {
                position: "absolute",
                height: "200px",
                width: "500px",
                top: "80px",
                left: "300px",
                right: "40px",
                bottom: "40px",
                border: "1px solid #fff",
                background: "#fff",
                overflow: "hidden",
                WebkitOverflowScrolling: "touch",
                borderRadius: "10px",
                outline: "none",
                padding: "20px",
              },
            }}
          >
            <section className="package-modal-parent">
              <div>
                <div className="modal-pack-name">
                  <label>Create Industry</label>
                  <input type="text" 
                  className="modal-package-input" 
                  value={createIndustry}
                  onChange={(e)=>setCreateIndustry(e.target.value)}
                  />
                </div>
                <div className="modal-pack-buttons"> 
                    <button className="pack-modal-create" onClick={handleIndustry}>Create</button>
                </div>
              </div>
            </section>
          </Modal>
          {/* Modal Ends */}
          <div className="something-change-in-app">
            {props.inAppChangesReducer.inAppChanges.industries && 
            props.inAppChangesReducer.inAppChanges.industries.length > 0 ?
            props.inAppChangesReducer.inAppChanges.industries.map( (ind)=>(
          <section className="change-child-01">
            <input type="radio" 
            className="change-input" 
            value={deleteIndustry}
            onChange={()=>setDeleteIndustry(ind.id)}
            name={deleteIndustry}
            />
            <h3>{ind.name}</h3>
          </section>
            )):""}
          </div>
          <button style={{height:"30px",width:"140px",marginTop:"30px",marginLeft:"200px",fontWeight:"bold",width:"80px"}} onClick={handleDeleteIndustry}>Delete</button>
        </div>

        <div className="changes-1" style={{marginLeft:"40px"}}>
          <h1 className="changes-heading">Skills</h1>
          <button className="changes-add-btn" onClick={()=>setSkillsModal(true)}>+ Add Skills</button>
          {/* Modal Starts */}
          <Modal
            isOpen={skillsModal}
            onRequestClose={() => setSkillsModal(false)}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "transparent",
              },
              content: {
                position: "absolute",
                height: "200px",
                width: "500px",
                top: "80px",
                left: "300px",
                right: "40px",
                bottom: "40px",
                border: "1px solid #fff",
                background: "#fff",
                overflow: "hidden",
                WebkitOverflowScrolling: "touch",
                borderRadius: "10px",
                outline: "none",
                padding: "20px",
              },
            }}
          >
            <section className="package-modal-parent">
              <div>
                <div className="modal-pack-name">
                  <label>Create Skills</label>
                  <input type="text" 
                  className="modal-package-input" 
                  value={createSkills}
                  onChange={(e)=>setCreateSkills(e.target.value)}
                  />
                </div>
                <div className="modal-pack-buttons"> 
                    <button className="pack-modal-create" onClick={handleCreateSkills}>Create</button>
                </div>
              </div>
            </section>
          </Modal>
          {/* Modal Ends */}
          <div className="something-change-in-app">
            {props.inAppChangesReducer.inAppChanges.skills && 
            props.inAppChangesReducer.inAppChanges.skills.length > 0 ?
            props.inAppChangesReducer.inAppChanges.skills.map( (skill)=>(
            <section className="change-child-01">
              <input type="radio" 
              className="change-input"
              value={deleteSkills}
              onChange={()=>setDeleteSkills(skill.id)}
              name={deleteSkills} 
              />
              <h3>{skill.name}</h3>
            </section>
            )):""}
          </div>
          <button style={{height:"30px",width:"140px",marginTop:"30px",marginLeft:"200px",fontWeight:"bold",width:"80px"}} onClick={handleDeleteSkills}>Delete</button>
        </div>
      </section>

      <section className="changes-row-2">
      <div className="changes-1">
          <h1 className="changes-heading">Add Languages</h1>
          <button className="changes-add-btn" onClick={()=>setLanguagesModal(true)}>+ add languages</button>
          {/* Modal Starts */}
          <Modal
            isOpen={languagesModal}
            onRequestClose={() => setLanguagesModal(false)}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "transparent",
              },
              content: {
                position: "absolute",
                height: "200px",
                width: "500px",
                top: "80px",
                left: "300px",
                right: "40px",
                bottom: "40px",
                border: "1px solid #fff",
                background: "#fff",
                overflow: "hidden",
                WebkitOverflowScrolling: "touch",
                borderRadius: "10px",
                outline: "none",
                padding: "20px",
              },
            }}
          >
            <section className="package-modal-parent">
              <div>
                <div className="modal-pack-name">
                  <label>Create Languages</label>
                  <input type="text" 
                  className="modal-package-input" 
                  value={createLanguages}
                  onChange={(e)=>setCreateLanguages(e.target.value)}
                  />
                </div>
                <div className="modal-pack-buttons"> 
                    <button className="pack-modal-create" onClick={handleLanguages}>Create</button>
                </div>
              </div>
            </section>
          </Modal>
          {/* Modal Ends */}
          <div className="something-change-in-app">
            {props.inAppChangesReducer.inAppChanges.languages &&
            props.inAppChangesReducer.inAppChanges.languages.length > 0 ?
            props.inAppChangesReducer.inAppChanges.languages.map( (lang)=>(
            <section className="change-child-01">
              <input type="radio" 
              className="change-input" 
              value={deleteLanguages}
              onChange={()=>setDeleteLanguages(lang.id)}
              name={deleteLanguages}
              />
              <h3>{lang.name}</h3>
            </section>
            ) ):""
            }
          
          </div>
          <button style={{height:"30px",width:"140px",marginTop:"30px",marginLeft:"200px",fontWeight:"bold",width:"80px"}} onClick={handleLanguagesDelete}>Delete</button>
        </div>

        <div className="changes-1" style={{marginLeft:"40px"}}>
          <h1 className="changes-heading">Add Industry</h1>
          <button className="changes-add-btn">+ Industry</button>
          <div className="something-change-in-app">
          <section className="change-child-01">
            <input type="checkbox" name="" id="" className="change-input" />
            <h3>Financial Managment</h3>
          </section>
          </div>
          <button style={{height:"30px",width:"140px",marginTop:"30px",marginLeft:"200px",fontWeight:"bold",width:"80px"}}>Delete</button>
        </div>

        <div className="changes-1" style={{marginLeft:"40px"}}>
          <h1 className="changes-heading">Add Industry</h1>
          <button className="changes-add-btn">+ Industry</button>
          <div className="something-change-in-app">
          <section className="change-child-01">
            <input type="checkbox" name="" id="" className="change-input" />
            <h3>Financial Managment</h3>
          </section>
          
          </div>
          <button style={{height:"30px",width:"140px",marginTop:"30px",marginLeft:"200px",fontWeight:"bold",width:"80px"}}>Delete</button>
        </div>
      </section>
    </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  inAppChangesReducer: state.inAppChangesReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getInAppChanges: (userId) => dispatch(getInAppChanges(userId)),

  postCreateIndustry: (userId, name) =>
    dispatch(postCreateIndustry(userId, name)),

  postCreateLanguages: (userId, name) =>
    dispatch(postCreateLanguages(userId, name)),

  postCreateFunctionalArea: (userId, name) =>
    dispatch(postCreateFunctionalArea(userId, name)),

  postCreateSkills: (userId, name) =>
    dispatch(postCreateSkills(userId, name)),

    postDeleteIndustry: (userId, deleteIndustry,to_del) =>
    dispatch(postDeleteIndustry(userId, deleteIndustry,to_del)),

    postDeleteLanguages: (userId, deleteLanguages,to_del) =>
    dispatch(postDeleteLanguages(userId, deleteLanguages,to_del)),

    postDeleteFunctionalArea: (userId, deleteFunctionalArea,to_del) =>
    dispatch(postDeleteFunctionalArea(userId, deleteFunctionalArea,to_del)),

    postDeleteSkills: (userId, deleteSkills,to_del) =>
    dispatch(postDeleteSkills(userId, deleteSkills,to_del)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InAppChanges);


