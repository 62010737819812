const initState = {
    inAppChanges: {},
    inAppChangesResponse: null,
}

const inAppChangesReducer = (state = initState, action) => {
    if (action.type === 'GET_INAPPCHANGES') {
        return {
            ...state,
            inAppChanges: action.inAppChanges,
            inAppChangesResponse:action.inAppChangesResponse
        }
    }
    else if (action.type === 'INAPPCHANGES_RESET') {
        return {
            ...state,
            inAppChangesResponse: null,
            
        }
    }
    else if (action.type === 'INAPPCHANGES_SUCCESS') {
        return {
            ...state,
            
            inAppChangesResponse: action.inAppChangesResponse,
        }
    }
    else if (action.type === 'INAPPCHANGES_FAIL') {
        return {
            ...state,
            
            inAppChangesResponse: action.inAppChangesResponse,
        }
    }

    return state;
}
export default inAppChangesReducer;