import React, { useState, useEffect } from "react";
import "./RoleManagment.css";
import Modal from "react-modal";
import { AiOutlinePlus } from "react-icons/ai";
import Navbar from "../../components/NavBar/Navbar";
import Sidebar from "../../components/SideBar/Sidebar";
import {
  getRoleManagment,
  postContactUs,
  deleteRole,
} from "../../Actions/roleManagmentAction";
import { connect } from "react-redux";

Modal.setAppElement("#root");

function RoleManagment(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [allRights, setAllRights] = useState(false);
  const [hanleUser, setHandleUser] = useState(false);
  const [banUser, setBanUser] = useState(false);
  const [handleRecruiter, setHandleRecruiter] = useState(false);
  const [banRecruiter, setBanRecruiter] = useState(false);

  useEffect(() => {
    loadGetPersonal(localStorage.getItem("auth_id"));
  }, []);
  const loadGetPersonal = async (userId) => {
    await props.getRoleManagment(userId);
    return null;
  };

  const handleRole = async () => {
    await props.postContactUs(
      localStorage.getItem("auth_id"),
      roleName,
      allRights == false ? 0 : 1,
      hanleUser == false ? 0 : 1,
      banUser == false ? 0 : 1,
      handleRecruiter == false ? 0 : 1,
      banRecruiter == false ? 0 : 1
    );
  };
  const deleteRole = async (e) => {
    await props.deleteRole(localStorage.getItem("auth_id"), e);
  };
  return (
    <>
      <Navbar />
      <Sidebar />
      <div>
        <section className="user-dash all-main">
          <div className="user-child-1">
            <p>Create Roles</p>
            <button onClick={() => setModalIsOpen(true)} className="role-buton">
              <h1>
                <AiOutlinePlus />
              </h1>
            </button>

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => setModalIsOpen(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "transparent",
                },
                content: {
                  position: "absolute",
                  height: "400px",
                  width: "400px",
                  top: "80px",
                  left: "300px",
                  right: "40px",
                  bottom: "40px",
                  border: "1px solid #fff",
                  background: "#fff",
                  overflow: "auto",
                  WebkitOverflowScrolling: "touch",
                  borderRadius: "20px",
                  outline: "none",
                  padding: "20px",
                  boxShadow: "0 0 5px 5px #f2f2f2",
                },
              }}
            >
              <section className="modal-create-roll">
                <h1>Create Role</h1>
                <div className="modal-role-name">
                  <label>ROLE NAME</label>
                  <input
                    type="text"
                    className="role-name-input"
                    value={roleName}
                    onChange={(e) => setRoleName(e.target.value)}
                  />
                </div>
                <section className="add-rights-parent">
                  <p>ADD RIGHTS</p>
                  <section className="rights-checkbox">
                    <div className="modal-check-001">
                      <input
                        type="checkbox"
                        className="modal-role-checkbox"
                        checked={allRights}
                        onChange={() => setAllRights(!allRights)}
                      />
                      <section className="modal-ver-line"></section>
                      <p>All Rights</p>
                    </div>
                    <div className="modal-check-001">
                      <input
                        type="checkbox"
                        className="modal-role-checkbox"
                        checked={hanleUser}
                        onChange={() => setHandleUser(!hanleUser)}
                      />
                      <section className="modal-ver-line"></section>
                      <p>Handle User</p>
                    </div>
                  </section>
                </section>
                <section className="add-rights-parent-02">
                  <section className="rights-checkbox">
                    <div className="modal-check-001">
                      <input
                        type="checkbox"
                        className="modal-role-checkbox"
                        checked={banUser}
                        onChange={() => setBanUser(!banUser)}
                      />
                      <section className="modal-ver-line"></section>
                      <p>Ban User</p>
                    </div>
                    <div className="modal-check-001">
                      <input
                        type="checkbox"
                        checked={handleRecruiter}
                        onChange={() => setHandleRecruiter(!handleRecruiter)}
                        className="modal-role-checkbox"
                      />
                      <section className="modal-ver-line"></section>
                      <p>Handle Recruiter</p>
                    </div>
                  </section>
                </section>
                <section className="add-rights-parent-02">
                  <section className="rights-checkbox">
                    <div className="modal-check-001">
                      <input
                        type="checkbox"
                        checked={banRecruiter}
                        onChange={() => setBanRecruiter(!banRecruiter)}
                        className="modal-role-checkbox"
                      />
                      <section className="modal-ver-line"></section>
                      <p>Ban Recruiter</p>
                    </div>
                  </section>
                </section>
                <div className="modal-btn-role">
                  <button
                    className="cancel-modal-btn"
                    onClick={() => setModalIsOpen(false)}
                  >
                    Cancel
                  </button>

                  <button className="create-modal-btn" onClick={handleRole}>
                    Create
                  </button>
                </div>
              </section>
            </Modal>
          </div>
        </section>

        <section className="role-table-parent">
          <div className="role-table-child-2">
            <div className="role-table-child-3">
              <table className="roles-det-table">
                <thead>
                  <tr>
                    <th className="user-first-radius">ROLES</th>
                    <th>RIGHT</th>
                    <th className="user-last-radius">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {props.roleManagmentReducer.roleManagment.admins &&
                  props.roleManagmentReducer.roleManagment.admins.length > 0
                    ? props.roleManagmentReducer.roleManagment.admins.map(
                        (item) => (
                          <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>
                              {item.rights.map((allroles) => ` ${allroles},`)}
                            </td>
                            <td>
                              <button
                                className="role-del-btn"
                                onClick={(e) => deleteRole(item.id)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        )
                      )
                    : ""}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  roleManagmentReducer: state.roleManagmentReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getRoleManagment: (userId) => dispatch(getRoleManagment(userId)),
  postContactUs: (
    userId,
    role_name,
    all_rights,
    handle_user,
    ban_user,
    handle_recruiter,
    ban_recruiter
  ) =>
    dispatch(
      postContactUs(
        userId,
        role_name,
        all_rights,
        handle_user,
        ban_user,
        handle_recruiter,
        ban_recruiter
      )
    ),
  deleteRole: (userId, role_id) => dispatch(deleteRole(userId, role_id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RoleManagment);



// code from scoopsolutions