const initState = {
    candidateDetails: {},
    candidateDetailsResponse: null,
    
}

const candidateDetailsReducer = (state = initState, action) => {
    if (action.type === 'GET_CANDIDATEDETAILS') {
        return {
            ...state,
            candidateDetails: action.candidateDetails,
            candidateDetailsResponse:action.candidateDetailsResponse
        }
    }
    else if (action.type === 'CANDIDATEDETAILS_RESET') {
        return {
            ...state,
            candidateDetailsResponse: null,
            
        }
    }
    else if (action.type === 'CANDIDATEDETAILS_SUCCESS') {
        return {
            ...state,
            
            candidateDetailsResponse: action.candidateDetailsResponse,
        }
    }
    else if (action.type === 'CANDIDATEDETAILS_FAIL') {
        return {
            ...state,
            
            candidateDetailsResponse: action.candidateDetailsResponse,
        }
    }

    return state;
}
export default candidateDetailsReducer;