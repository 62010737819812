const initState = {
    candidate: {},
    candidateResponse: null,
}

const candidatesReducer = (state = initState, action) => {
    if (action.type === 'GET_CANDIDATE') {
        return {
            ...state,
            candidate: action.candidate,
            candidateResponse: action.candidateResponse
        }
    }
    else if (action.type === 'CANDIDATE_RESET') {
        return {
            ...state,
            candidateResponse: null,

        }
    }
    else if (action.type === 'CANDIDATE_SUCCESS') {
        return {
            ...state,

            candidateResponse: action.candidateResponse,
        }
    }
    else if (action.type === 'CANDIDATE_FAIL') {
        return {
            ...state,

            candidateResponse: action.candidateResponse,
        }
    }

    return state;
}
export default candidatesReducer;