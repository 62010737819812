import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import configureStore from './Store';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { applyMiddleware, compose, createStore } from 'redux';
import mainReducer from './reducers/Rootreducer'
import persistStore from 'redux-persist/es/persistStore';
import thunk from 'redux-thunk';
import { PersistGate } from 'redux-persist/integration/react';
import 'antd/dist/antd.css';

import reportWebVitals from './reportWebVitals';
const store = createStore(mainReducer, applyMiddleware(thunk));
const persistor = persistStore(store);


ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
        <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
reportWebVitals();
