const initState = {
    userInfo: {},
    userInfoResponse: null,
    
}

const userInfoReducer = (state = initState, action) => {
    if (action.type === 'GET_USERINFO') {
        return {
            ...state,
            userInfo: action.userInfo,
            userInfoResponse:action.userInfoResponse
        }
    }
    else if (action.type === 'USERINFO_RESET') {
        return {
            ...state,
            userInfoResponse: null,
            
        }
    }
    else if (action.type === 'USERINFO_SUCCESS') {
        return {
            ...state,
            
            userInfoResponse: action.userInfoResponse,
        }
    }
    else if (action.type === 'USERINFO_FAIL') {
        return {
            ...state,
            
            userInfoResponse: action.userInfoResponse,
        }
    }

    return state;
}
export default userInfoReducer;