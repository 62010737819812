const config = require('../helpers/config.json');

export const getInAppChanges = (userId) => {
    return (dispatch) => {
        /// get request
        fetch(`${config['baseUrl']}/admin/all_dropdowns.php`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "auth_id": `${userId}` },
        }).then(res => res.json()).then((response) => {
            const inAppChanges = response.data

            // console.log("kkkkk", response);
            dispatch({
                type: "GET_INAPPCHANGES",
                inAppChanges: inAppChanges,
                inAppChangesResponse: "got it"
            });
        }).catch((error) => {
            console.log("error", error);
            dispatch({
                type: "GET_INAPPCHANGES",
                inAppChanges: {},
                inAppChangesResponse: null
            });
        })

    }
}

export const postCreateIndustry = (userId, name) => {
    console.log(userId, name)
    return (dispatch) => {
        dispatch({
            type: "INAPPCHANGES_RESET",
        });
        var data =
        {
            "name": name
        }

        console.log("ddddd", data)
        /// post request
        fetch(`${config['baseUrl']}/admin/create_industry.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "auth_id": `${userId}`, },
            body: JSON.stringify({
                "name": name
            })
        }).then((response) => {
            console.log("pppppp", response);
            dispatch({
                type: "INAPPCHANGES_SUCCESS",
                inAppChangesResponse: response,
            });
            window.location = "/inAppchanges"

        }).catch((error) => {
            console.log(error)
            dispatch({
                type: "INAPPCHANGES_FAIL",
                inAppChangesResponse: "creation failed",
                //pageName: PGN.COLORS_PAGE_NAME
            });
            alert("Please Check Your Internet Connection...")
        })
    }
}

export const postCreateLanguages = (userId, name) => {
    console.log(userId, name)
    return (dispatch) => {
        dispatch({
            type: "INAPPCHANGES_RESET",
        });
        var data =
        {
            "name": name
        }

        console.log("ddddd", data)
        /// post request
        fetch(`${config['baseUrl']}/admin/create_languages.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "auth_id": `${userId}`, },
            body: JSON.stringify({
                "name": name
            })
        }).then((response) => {
            console.log("pppppp", response);
            dispatch({
                type: "INAPPCHANGES_SUCCESS",
                inAppChangesResponse: response,
            });
            window.location = "/inAppchanges"

        }).catch((error) => {
            console.log(error)
            dispatch({
                type: "INAPPCHANGES_FAIL",
                inAppChangesResponse: "creation failed",
                //pageName: PGN.COLORS_PAGE_NAME
            });
            alert("Please Check Your Internet Connection...")
        })
    }
}


export const postCreateFunctionalArea = (userId, name) => {
    console.log(userId, name)
    return (dispatch) => {
        dispatch({
            type: "INAPPCHANGES_RESET",
        });
        var data =
        {
            "name": name
        }

        console.log("ddddd", data)
        /// post request
        fetch(`${config['baseUrl']}/admin/create_functionalarea.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "auth_id": `${userId}`, },
            body: JSON.stringify({
                "name": name
            })
        }).then((response) => {
            console.log("pppppp", response);
            dispatch({
                type: "INAPPCHANGES_SUCCESS",
                inAppChangesResponse: response,
            });
            window.location = "/inAppchanges"

        }).catch((error) => {
            console.log(error)
            dispatch({
                type: "INAPPCHANGES_FAIL",
                inAppChangesResponse: "creation failed",
                //pageName: PGN.COLORS_PAGE_NAME
            });
            alert("Please Check Your Internet Connection...")
        })
    }
}


export const postCreateSkills = (userId, name) => {
    console.log(userId, name)
    return (dispatch) => {
        dispatch({
            type: "INAPPCHANGES_RESET",
        });
        var data =
        {
            "name": name
        }

        console.log("ddddd", data)
        /// post request
        fetch(`${config['baseUrl']}/admin/create_skills.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "auth_id": `${userId}`, },
            body: JSON.stringify({
                "name": name
            })
        }).then((response) => {
            console.log("pppppp", response);
            dispatch({
                type: "INAPPCHANGES_SUCCESS",
                inAppChangesResponse: response,
            });
            window.location = "/inAppchanges"

        }).catch((error) => {
            console.log(error)
            dispatch({
                type: "INAPPCHANGES_FAIL",
                inAppChangesResponse: "creation failed",
                //pageName: PGN.COLORS_PAGE_NAME
            });
            alert("Please Check Your Internet Connection...")
        })
    }
}



export const postDeleteIndustry = (userId, id, to_del) => {
    console.log(userId, id, to_del)
    return (dispatch) => {
        dispatch({
            type: "INAPPCHANGES_RESET",
        });
        var data =
        {
            "id": id,
            "to_del": to_del
        }

        console.log("ddddd", data)
        /// post request
        fetch(`${config['baseUrl']}/admin/delete_dropdowns.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "auth_id": `${userId}`, },
            body: JSON.stringify({
                "id": id,
                "to_del": to_del
            })
        }).then((response) => {
            console.log("pppppp", response);
            dispatch({
                type: "INAPPCHANGES_SUCCESS",
                inAppChangesResponse: response,
            });
            window.location = "/inAppchanges"

        }).catch((error) => {
            console.log(error)
            dispatch({
                type: "INAPPCHANGES_FAIL",
                inAppChangesResponse: "creation failed",
                //pageName: PGN.COLORS_PAGE_NAME
            });
            alert("Please Check Your Internet Connection...")
        })
    }
}

export const postDeleteLanguages = (userId, id, to_del) => {
    console.log(userId, id, to_del)
    return (dispatch) => {
        dispatch({
            type: "INAPPCHANGES_RESET",
        });
        var data =
        {
            "id": id,
            "to_del": to_del
        }

        console.log("ddddd", data)
        /// post request
        fetch(`${config['baseUrl']}/admin/delete_dropdowns.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "auth_id": `${userId}`, },
            body: JSON.stringify({
                "id": id,
                "to_del": to_del
            })
        }).then((response) => {
            console.log("pppppp", response);
            dispatch({
                type: "INAPPCHANGES_SUCCESS",
                inAppChangesResponse: response,
            });
            window.location = "/inAppchanges"

        }).catch((error) => {
            console.log(error)
            dispatch({
                type: "INAPPCHANGES_FAIL",
                inAppChangesResponse: "creation failed",
                //pageName: PGN.COLORS_PAGE_NAME
            });
            alert("Please Check Your Internet Connection...")
        })
    }
}

export const postDeleteFunctionalArea = (userId, id, to_del) => {
    console.log(userId, id, to_del)
    return (dispatch) => {
        dispatch({
            type: "INAPPCHANGES_RESET",
        });
        var data =
        {
            "id": id,
            "to_del": to_del
        }

        console.log("ddddd", data)
        /// post request
        fetch(`${config['baseUrl']}/admin/delete_dropdowns.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "auth_id": `${userId}`, },
            body: JSON.stringify({
                "id": id,
                "to_del": to_del
            })
        }).then((response) => {
            console.log("pppppp", response);
            dispatch({
                type: "INAPPCHANGES_SUCCESS",
                inAppChangesResponse: response,
            });
            window.location = "/inAppchanges"

        }).catch((error) => {
            console.log(error)
            dispatch({
                type: "INAPPCHANGES_FAIL",
                inAppChangesResponse: "creation failed",
                //pageName: PGN.COLORS_PAGE_NAME
            });
            alert("Please Check Your Internet Connection...")
        })
    }
}


export const postDeleteSkills = (userId, id, to_del) => {
    console.log(userId, id, to_del)
    return (dispatch) => {
        dispatch({
            type: "INAPPCHANGES_RESET",
        });
        var data =
        {
            "id": id,
            "to_del": to_del
        }

        console.log("ddddd", data)
        /// post request
        fetch(`${config['baseUrl']}/admin/delete_dropdowns.php`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "auth_id": `${userId}`, },
            body: JSON.stringify({
                "id": id,
                "to_del": to_del
            })
        }).then((response) => {
            console.log("pppppp", response);
            dispatch({
                type: "INAPPCHANGES_SUCCESS",
                inAppChangesResponse: response,
            });
            window.location = "/inAppchanges"

        }).catch((error) => {
            console.log(error)
            dispatch({
                type: "INAPPCHANGES_FAIL",
                inAppChangesResponse: "creation failed",
                //pageName: PGN.COLORS_PAGE_NAME
            });
            alert("Please Check Your Internet Connection...")
        })
    }
}


