const config = require('../helpers/config.json');

export const getCustomerSupport = (userId) => {
    return (dispatch) => {
        /// get request
        fetch(`https://api.cvvlogs.com/cv-tube/api.v.1/admin/costumer_support_get.php`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "auth_id": `${userId}` },
        }).then(res => res.json()).then((response) => {
            const customerSupport = response.data

            // console.log("kkkkk", response);
            dispatch({
                type: "GET_CUSTOMER_SUPPORT",
                customerSupport: customerSupport,
                customerSupportResponse: "got it"
            });
        }).catch((error) => {
            console.log("error", error);
            dispatch({
                type: "GET_CUSTOMER_SUPPORT",
                customerSupport: {},
                customerSupportResponse: null
            });
        })

    }


}

