import React, { useEffect } from "react";
import "../Customer-Support/CustomerSupport.css";
import hello from "../../assests/icon-cus-supp.svg";
import Navbar from "../../components/NavBar/Navbar";
import Sidebar from "../../components/SideBar/Sidebar";
import { getCustomerSupport } from "../../Actions/customerSupportAction";
import { connect } from "react-redux";

function CustomerSupport(props) {
  useEffect(() => {
    loadGetPersonal(localStorage.getItem("auth_id"));
  }, []);
  const loadGetPersonal = async (userId) => {
    await props.getCustomerSupport(userId);
    return null;
  };
  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="all-main-01">
        <section className="supp-dash">
          <div className="supp-child-1">
            <p>Total messages</p>
            <h1>
              {props.customerSupportReducer.customerSupport &&
              props.customerSupportReducer.customerSupport.total !== null &&
              props.customerSupportReducer.customerSupport.total !== undefined
                ? props.customerSupportReducer.customerSupport.total
                : ""}
            </h1>
            <button
              onClick={() =>
                console.log(props.customerSupportReducer.customerSupport.casses)
              }
            >
              Click me
            </button>
          </div>
          <div className="supp-child-2">
            <p>Unread</p>
            <h1>
              {props.customerSupportReducer.customerSupport &&
              props.customerSupportReducer.customerSupport.unread !== null &&
              props.customerSupportReducer.customerSupport.unread !== undefined
                ? props.customerSupportReducer.customerSupport.unread
                : ""}
            </h1>
          </div>
          <div className="supp-child-3">
            <p>Read</p>
            <h1>
              {props.customerSupportReducer.customerSupport &&
              props.customerSupportReducer.customerSupport.read !== null &&
              props.customerSupportReducer.customerSupport.read !== undefined
                ? props.customerSupportReducer.customerSupport.read
                : ""}
            </h1>
          </div>
        </section>

        <section className="cus-table-parent">
          <div className="sub-table-div">
            <div className="customer-table">
              <table className="cus-supp">
                <thead>
                  <tr>
                    <th className="user-first-radius">NAME</th>
                    <th>EMAIL</th>
                    <th>MESSAGE</th>
                    <th className="user-last-radius">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {props.customerSupportReducer.customerSupport.casses &&
                  props.customerSupportReducer.customerSupport.casses.length >
                    0 &&
                  props.customerSupportReducer.customerSupport.casses !== null
                    ? props.customerSupportReducer.customerSupport.casses.map(
                        (admin) => (
                          <tr>
                            <td>{admin.name}</td>
                            <td>{admin.email}</td>
                            <td>
                              {admin.msg}
                            </td>
                            <td>
                              <img
                                src={hello}
                                className="align-action-to-cen"
                              />
                            </td>
                          </tr>
                        )
                      )
                    : ""}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  customerSupportReducer: state.customerSupportReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerSupport: (userId) => dispatch(getCustomerSupport(userId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerSupport);
