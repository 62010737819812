const initState = {
    sales: {},
    salesResponse: null,
    
}

const salesReducer = (state = initState, action) => {
    if (action.type === 'GET_SALES') {
        return {
            ...state,
            sales: action.sales,
            salesResponse:action.salesResponse
        }
    }
    else if (action.type === 'SALES_RESET') {
        return {
            ...state,
            salesResponse: null,
            
        }
    }
    else if (action.type === 'SALES_SUCCESS') {
        return {
            ...state,
            
            salesResponse: action.salesResponse,
        }
    }
    else if (action.type === 'SALES_FAIL') {
        return {
            ...state,
            
            salesResponse: action.salesResponse,
        }
    }

    return state;
}
export default salesReducer;