const initState = {
    allqualification: {},
    allqualificationResponse: null,
}

const allqualificationReducer = (state = initState, action) => {
    if (action.type === 'GET_ALLQUALIFICATION') {
        return {
            ...state,
            allqualification: action.allqualification,
            allqualificationResponse: action.allqualificationResponse
        }
    }
    else if (action.type === 'ALLQUALIFICATION_RESET') {
        return {
            ...state,
            allqualificationResponse: null,

        }
    }
    else if (action.type === 'ALLQUALIFICATION_SUCCESS') {
        return {
            ...state,

            allqualificationResponse: action.allqualificationResponse,
        }
    }
    else if (action.type === 'ALLQUALIFICATION_FAIL') {
        return {
            ...state,

            allqualificationResponse: action.allqualificationResponse,
        }
    }

    return state;
}
export default allqualificationReducer;