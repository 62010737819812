const initState = {
    allprofessional: {},
    allprofessionalResponse: null,
}

const allprofessionalReducer = (state = initState, action) => {
    if (action.type === 'GET_ALLPROFESSIONAL') {
        return {
            ...state,
            allprofessional: action.allprofessional,
            allprofessionalResponse: action.allprofessionalResponse
        }
    }
    else if (action.type === 'ALLPROFESSIONAL_RESET') {
        return {
            ...state,
            allprofessionalResponse: null,

        }
    }
    else if (action.type === 'ALLPROFESSIONAL_SUCCESS') {
        return {
            ...state,

            allprofessionalResponse: action.allprofessionalResponse,
        }
    }
    else if (action.type === 'ALLPROFESSIONAL_FAIL') {
        return {
            ...state,

            allprofessionalResponse: action.allprofessionalResponse,
        }
    }

    return state;
}
export default allprofessionalReducer;