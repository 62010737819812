const initState = {
    createRole: {},
    createRoleResponse: null,
}

const createRoleReducer = (state = initState, action) => {
    if (action.type === 'GET_CREATE_ROLE') {
        return {
            ...state,
            createRole: action.createRole,
            createRoleResponse: action.createRoleResponse,
        }
    }
    // /// THESE ARE GENERAL APPLICABLE TO ALL API's
    else if (action.type === 'CREATE_ROLE_RESET') {
        return {
            ...state,
            createRoleResponse: null,

        }
    }
    else if (action.type === 'CREATE_ROLE_SUCCESS') {
        return {
            ...state,
            createRoleResponse: action.createRoleResponse,
        }
    }
    else if (action.type === 'CREATE_ROLE_FAIL') {
        return {
            ...state,
            createRoleResponse: action.createRoleResponse,
        }
    }

    return state;
}
export default createRoleReducer;